<template>
  <div>
    <div
      class="modalScroll d-flex flex-column align-items-center"
      style="width: auto; height: auto"
    >

      <b-tabs>
        <b-tab title="Performance Revert Form">
          <div
            class="mb-1 d-flex justify-content-center"
            style="font-size: 1.9rem; font-weight: 500;"
          >
            {{ $t("Revert Submitted Form") }}
            <!-- {{ isHod === 'HOD' ? 'HOD' : 'not HOD' }} -->
            <br>
          </div>

          <div
            class="p-1"
            style=""
          >
            <b-form-radio-group
              v-if="isHod === 'HOD'"
              v-model="reverted"
              :options="roles.selectedRole === 1 ? options_SHR_HOD : options"
              stacked
              class="styleFont"
            />
            <b-form-radio-group
              v-else
              v-model="reverted"
              :options="roles.selectedRole === 1 ? options_SHR : options"
              stacked
              class="styleFont"
            />
          </div>

          <b-row class="d-flex justify-content-center">
            <button
              class="btn btn-close m-1 saj-subtitle"
              aria-label="Close modal"
              style="color: white; background: #ff0000;"
              @click="close()"
            >
              {{ $t("Back") }}
            </button>
            <button
              class="btn m-1 saj-subtitle"
              aria-label="submit modal"
              style="color: white;
                     background: #0b103c !important;
                     border-color: #0b103c !important;
                    "
              @click="revertPerformance()"
            >
              {{ $t("Save") }}
            </button>
          </b-row>
        </b-tab>
        <b-tab title="Part 1 - KRA Revert Form">

          <div class="p-1 saj-text">
            <p class="d-flex justify-content-between align-items-center">Performance Planning <button
              class="btn btn-close m-1 saj-subtitle"
              style="color: white; background: #ff0000;"
              @click="revertKra('planning')"
            >
              {{ $t("Revert") }}
            </button></p>
            <p class="d-flex justify-content-between align-items-center">Performance Review <button
              class="btn btn-close m-1 saj-subtitle"
              style="color: white; background: #ff0000;"
              @click="revertKra('review')"
            >
              {{ $t("Revert") }}
            </button></p>
            <p class="d-flex justify-content-between align-items-center">Year End Result <button
              class="btn btn-close m-1 saj-subtitle"
              style="color: white; background: #ff0000;"
              @click="revertKra('result')"
            >
              {{ $t("Revert") }}
            </button></p>
          </div>

        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BFormRadioGroup,
  BTabs,
  BTab,
} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BFormRadioGroup,
    BTabs,
    BTab,
  },
  props: {
    performance: {
      type: Number,
      default: null,
    },
    user: {
      type: Number,
      default: null,
    },
    approval: {
      type: Object,
      default: () => {},
    },
    employeeRole: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      reverted: null,
      unsubmit: null,
      disagree: null,
      isHod: null,
      //   allRevertedOptions: ['unsubmit', 'disagree'],

      options: [
        { text: `${this.$i18n.t('Unsubmit')}`, value: 'unsubmit', disabled: this.approval.submit === 0 },
        { text: `${this.$i18n.t('Disagree')}`, value: 'disagree', disabled: this.approval.agree === 0 },
        { text: `${this.$i18n.t('Disapprove Supervisor')}`, value: 'sv_disapprove', disabled: this.approval.approved_sv === 0 },
        { text: `${this.$i18n.t('Disapprove Head of Department')}`, value: 'hod_disapprove', disabled: this.approval.approved_hod === 0 },
      ],

      options_SHR: [
        { text: `${this.$i18n.t('Unsubmit')}`, value: 'unsubmit', disabled: this.approval.submit === 0 },
        { text: `${this.$i18n.t('Disagree')}`, value: 'disagree', disabled: this.approval.agree === 0 },
        { text: `${this.$i18n.t('Disapprove Supervisor')}`, value: 'sv_disapprove', disabled: this.approval.approved_sv === 0 },
        { text: `${this.$i18n.t('Disapprove Head of Department')}`, value: 'hod_disapprove', disabled: this.approval.approved_hod === 0 },
        { text: `${this.$i18n.t('Disapprove Human Resources')}`, value: 'hr_disapprove', disabled: this.approval.approved_hr === 0 },
        { text: `${this.$i18n.t('Disapprove Super HR')}`, value: 'shr_disapprove', disabled: this.approval.approved_shr === 0 },
      ],
      options_SHR_HOD: [
        { text: `${this.$i18n.t('Unsubmit')}`, value: 'unsubmit', disabled: this.approval.submit === 0 },
        { text: `${this.$i18n.t('Disagree')}`, value: 'disagree', disabled: this.approval.agree === 0 },
        { text: `${this.$i18n.t('Disapprove Supervisor')}`, value: 'sv_disapprove', disabled: this.approval.approved_sv === 0 },
        { text: `${this.$i18n.t('Disapprove Head of Division')}`, value: 'head_division_disapprove', disabled: this.approval.approved_head_division === 0 },
        { text: `${this.$i18n.t('Disapprove Human Resources')}`, value: 'hr_disapprove', disabled: this.approval.approved_hr === 0 },
        { text: `${this.$i18n.t('Disapprove Super HR')}`, value: 'shr_disapprove', disabled: this.approval.approved_shr === 0 },
      ],

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {
    const employeeRole = this.employeeRole.map(x => (
      x
    ))

    employeeRole.forEach(x => {
      if (x === 5) {
        this.isHod = 'HOD'
      }
    })
  },
  methods: {
    close() {
      this.$emit("close")
    },

    revertPerformance() {
      // console.log("hereeeeee", this.reverted)
      const data = new FormData()
      data.append(`performance_id[]`, this.performance)
      data.append(`${this.reverted}`, 1)

      this.$axios
        .post(`${this.$baseUrl}/performance/disapprove`, data)
        .then(() => {
          setTimeout(() => {
            this.$emit('revert-performance')
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Performance successfully updated')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            this.close()
          }, 1000)
        }).catch(error => {
        // console.log(error.response)
        // this.disableInput = true
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: error.response.data.message,
                icon: "XIcon",
                variant: "danger",
                iconBg: '#e80202',
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.$emit("close")
        })
    },
    revertKra(a){
      const data = new FormData()
      const planning = 'revert_planning'
      const review = 'revert_review'
      const result = 'revert_result'

      let api = '/kra/'

      switch (a){
        case 'planning':

          api += planning
          break

        case 'review':
          api += review
          break
        case 'result':
          api += result
          break
        default:
      }
      data.append(`performance_id`, this.performance)

      // console.log('api', api)

      this.$axios
        .post(`${this.$baseUrl}${api}`, data)
        .then(() => {
          // console.log('res', res)
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Performance successfully updated')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            this.close()
          }, 1000)
        }).catch(error => {
        // console.log(error.response)
        // this.disableInput = true
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: error.response.data.message,
                icon: "XIcon",
                variant: "danger",
                iconBg: '#e80202',
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.$emit("close")
        })
    },
  },
}
</script>
<style>
.styleFont {
    font-size: 2.5rem !important;
}
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
    font-size: 1.5rem;
    position: static;
}

</style>
